import React, { Component } from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { Flex, Box, Heading, Button } from "rebass";
import Text from "../components/styles/Text";
import { plural } from "../utils/helpers";
import Container from "../components/styles/Container";
import Modal from "react-modal";
import BodyText from "../components/styles/BodyText";
import Page from "../components/Page";
import { graphql, navigateTo } from "gatsby";
import { navigate } from "@reach/router";
import Helmet from "react-helmet";
import moment from "moment";
import DesignBuildDrive from "../components/home/DesignBuildDrive";

const PostContainer = styled(Container)`
  @media only screen and (max-width: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const HeadImage = styled(Img)`
  max-width: 120px;
  min-width: 80px;
`;

const FormBanner = styled.div`
  height: 200px;
  /*
  max-width: 1400px; */
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 60px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: rgb(52, 122, 188);
  color: white;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;

export default class PostPage extends Component {
  render() {
    const { data } = this.props;
    const {
      headingTitle,
      headingSubtitle,
      headingGraphic,
      bodyImageMain,
      bodyImageSecondary,
      bodyText,
      locationCoordinates,
      bodyPreview,
      slug,
      publishDate
    } = this.props.data.contentfulLandingPage;

    console.log(locationCoordinates.lat + "," + locationCoordinates.lon);
    return (
      <div>
        <Page>
          <Helmet>
            <title>{`${headingTitle} | ${data.site.siteMetadata.title}`}</title>
            {/* <meta name="description" content={bodyPreview} /> */}
            {/* <meta name="image" content={`https://${firstURL}`} />
            <meta
              property="og:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta property="og:image" content={`https://${firstURL}`} />
            <meta property="og:description" content={bodyPreview} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@modarri_cars" />
            <meta
              name="twitter:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta name="twitter:description" content={bodyPreview} />
            <meta name="twitter:image" content={`https://${firstURL}`} /> */}
          </Helmet>
          <FormBanner>
            <Flex
              maxWidth="1000px"
              width="100%"
              flexWrap="wrap"
              mx="auto"
              pt="20px"
            >
              <Box width={1 / 5} py="30px" px="10px">
                <HeadImage fluid={headingGraphic.sizes} />
              </Box>
              <Box
                width={4 / 5}
                pt={["40px", "50px", "40px"]}
                pl={["40px", "10px", "0px"]}
              >
                <Heading fontSize={[2, 4, 5]}>
                  {headingTitle.toUpperCase()}
                </Heading>
                <Heading fontSize={[2, 4, 5]}>
                  {headingSubtitle.toUpperCase()}
                </Heading>
              </Box>
            </Flex>
          </FormBanner>
          <Container mx="auto" px={5}>
            {/* Parent Flex container  */}
            <Flex flexWrap="wrap" mx="auto">
              <Box width={[1, 1, 1 / 2]} py="30px">
                <Img fluid={bodyImageMain.sizes} />

                {/* Secondary Boxes */}
                <Flex flexWrap="wrap">
                  <Box width={1 / 2} py={5} pr={2}>
                    <Img fluid={bodyImageSecondary.sizes} />
                  </Box>
                  <Box width={1 / 2} py={5} pl={2} minHeight="145px">
                    <iframe
                      width="100%"
                      height="100%"
                      frameborder="0"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAwe11MFaEh8OT5yW8e1SPEv5Pf1I7Wb6w
                          &q=${locationCoordinates.lat +
                            "," +
                            locationCoordinates.lon}`}
                      allowfullscreen
                    />
                  </Box>
                </Flex>
              </Box>

              <Box width={[1, 1, 1 / 2]}>
                <PostContainer px={5} mx="2%">
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: bodyText.childMarkdownRemark.html
                    }}
                  />
                </PostContainer>
              </Box>
            </Flex>
            <DesignBuildDrive />
          </Container>
        </Page>
      </div>
    );
  }
}

export const query = graphql`
  query LandingPageQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLandingPage(slug: { eq: $slug }) {
      id
      headingTitle
      headingSubtitle
      headingGraphic {
        sizes(maxWidth: 800) {
          ...GatsbyContentfulSizes
        }
      }

      bodyText {
        childMarkdownRemark {
          html
        }
      }

      bodyImageMain {
        sizes(maxWidth: 800) {
          ...GatsbyContentfulSizes
        }
      }
      bodyImageSecondary {
        sizes(maxWidth: 800) {
          ...GatsbyContentfulSizes
        }
      }
      locationCoordinates {
        lat
        lon
      }
    }
  }
`;
