import styled from 'styled-components';
import Text from './Text';

export default styled(Text)`
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: -0.003em;
  font-size: 20px;
  line-height: 1.6em;
  color: #3a4145;
  -webkit-font-smoothing: antialiased;
`;
