import { useEffect } from 'react';
import { navigate } from 'gatsby-link';

export function plural(count, noun, suffix = 's') {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}

const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export function tagColor(tag) {
  if (tag === 'SUCCESS') return '#88f992';
  else if (tag === 'FAILED') return '#e69b9c';
  else if (tag === 'EXPERIMENT') return '#de98ff';
  else if (tag === 'BUILDING') return '#0266ff';
}

export const onEnter = async (e, method) => {
  if (e.key === 'Enter') await method();
};

export const loginRedirectEffect = (token, loadingToken, location) => {
  useEffect(
    () => {
      if (!token && !loadingToken) {
        navigate(`/login${location.search}`);
      }
    },
    [token, loadingToken, location]
  );
};

export function stringFormat(s, ...rest) {
  return s.replace(
    /{(\d+)}/g,
    (match, number) =>
      typeof rest[number] !== 'undefined' ? rest[number] : match
  );
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
