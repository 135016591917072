import React, { Component } from "react";
import { Flex, Heading } from "rebass";
import Text from "../styles/Text";
import styled from "styled-components";
import Img from "../styles/Img";
import Box from "../styles/Box";
import { graphql, StaticQuery } from "gatsby";
import { padding } from "../../theme";
import Subhead from "../styles/Subhead";
import SecondaryButton from "../styles/SecondaryButton";
import { Link } from "gatsby";
import LightButton from "../styles/LightButton";

const BlockImage = styled.img`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const SSecondaryButton = styled(LightButton)`
  &:hover {
    cursor: pointer !Important;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query DesignBuildDrive {
        contentfulHomePage(live: { eq: true }) {
          id
          element3Title
          element3Block1Title
          element3Block1Subhead
          element3Block1Image {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          element3Block2Title
          element3Block2Subhead
          element3Block2Image {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          element3Block3Title
          element3Block3Subhead
          element3Block3Image {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Subhead pt={padding} pb="20px" pl="10px" fontSize={["32px", "40px"]}>
          {data.contentfulHomePage.element3Title.toUpperCase()}
        </Subhead>
        <Flex pb={padding} flexWrap="wrap">
          <Box width={[1, 1, 1 / 3]} px={3} color="black">
            <Subhead py={2} fontSize="30px">
              {data.contentfulHomePage.element3Block1Title.toUpperCase()}
            </Subhead>
            <Img fluid={data.contentfulHomePage.element3Block1Image.fluid} />

            <Text py={5} fontSize="20px" fontFamily="Open Sans">
              {data.contentfulHomePage.element3Block1Subhead}
            </Text>
          </Box>
          {/* Box 2 */}
          <Box width={[1, 1, 1 / 3]} px={3} color="black">
            <Subhead py={2} fontSize="30px">
              {data.contentfulHomePage.element3Block2Title.toUpperCase()}
            </Subhead>
            <Img fluid={data.contentfulHomePage.element3Block2Image.fluid} />

            <Text py={5} fontSize="20px" fontFamily="Open Sans">
              {data.contentfulHomePage.element3Block2Subhead}
            </Text>
          </Box>
          {/* Box 3 */}
          <Box width={[1, 1, 1 / 3]} px={3} color="black">
            <Subhead py={2} fontSize="30px">
              {data.contentfulHomePage.element3Block3Title.toUpperCase()}
            </Subhead>
            <Img fluid={data.contentfulHomePage.element3Block3Image.fluid} />

            <Text py={5} fontSize="20px" fontFamily="Open Sans">
              {data.contentfulHomePage.element3Block3Subhead}
            </Text>
          </Box>
          <Box w={1} alignItems="center" mx="auto">
            <a href="https://modarri-toy-cars.myshopify.com/">
              <SSecondaryButton>Shop Now</SSecondaryButton>
            </a>
          </Box>
        </Flex>
      </div>
    )}
  />
);
