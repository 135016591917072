import styled from 'styled-components';
import { Heading } from 'rebass';

import { colors } from '../../theme';

export default styled(Heading)`

color: rgb(55, 65, 69);
font-family: 'Source Sans Pro', sans-serif !Important;
font-style: italic;
font-display: swap;

-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;



  /* color: ${colors.gray9}; */

  /* &:hover {
    color: ${colors.base};
    cursor: pointer;
  } */
`;
