import styled from 'styled-components';
import { Button } from 'rebass';
import React from 'react';

// import { shadows } from '../../theme';

const LightButton = props => (
  <Button
    {...props}
    sx={{
      fontSize: '22px',
      fontWeight: '700',
      px: '20px',
      py: '12px',
      m: '0',
      color: '#fff',
      backgroundColor: '#047bc1',
    }}
  />
);

export default LightButton;

// export default styled(Button)`

//   /* box-shadow: ${shadows.small}; */
//   font-size: 22px;
//   font-weight: 700;
//   line-height: 1.1428571428571428;
//   margin: 0px;
//   padding-left: 20px;
//   padding-right: 20px;
//   padding-top: 12px;
//   padding-bottom: 12px;
//   color: #fff;
//   background-color: #047bc1;

//   &:focus {
//     /* box-shadow: ${shadows.outline}; */
//   }
//   &:hover {
//     transform: translate(1px, -1px);
//     cursor: pointer;
//     background-color: #047bc0;
//   }
// `;
